// Generated by ReScript, PLEASE EDIT WITH CARE


function value($$event) {
  return $$event.target.value;
}

function checked($$event) {
  return $$event.target.checked;
}

export {
  value ,
  checked ,
}
/* No side effect */

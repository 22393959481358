// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              long_name: field.required("long_name", Json_Decode$JsonCombinators.string),
              short_name: field.required("short_name", Json_Decode$JsonCombinators.string),
              types: field.required("types", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Address = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              address_components: field.required("address_components", Json_Decode$JsonCombinators.array(decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Gmaps = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              lat: field.required("lat", Json_Decode$JsonCombinators.$$float),
              lng: field.required("lng", Json_Decode$JsonCombinators.$$float)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var $$Location = {
  decoder: decoder$2,
  fromJson: fromJson$2
};

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              label: field.required("label", Json_Decode$JsonCombinators.string),
              location: field.required("location", decoder$2),
              gmaps: field.required("gmaps", Json_Decode$JsonCombinators.option(decoder$1))
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var ReactGeoSuggestData = {
  decoder: decoder$3,
  fromJson: fromJson$3
};

export {
  Address ,
  Gmaps ,
  $$Location ,
  ReactGeoSuggestData ,
}
/* decoder Not a pure module */

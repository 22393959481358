// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconOk from "../../icons/IconOk.res.js";
import * as Spinner from "../../components/Spinner/Spinner.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import ReactGeosuggest from "@ubilabs/react-geosuggest";
import * as ReactGeoSuggestFieldScss from "./ReactGeoSuggestField.scss";

var css = ReactGeoSuggestFieldScss;

function ReactGeoSuggestField(props) {
  var onSuggestSelect = props.onSuggestSelect;
  var __className = props.className;
  var status = props.status;
  var __disabled = props.disabled;
  var __placeholder = props.placeholder;
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var disabled = __disabled !== undefined ? __disabled : false;
  var className = __className !== undefined ? __className : "";
  var tmp;
  if (status !== undefined) {
    switch (status) {
      case "Error" :
          tmp = css.error;
          break;
      case "Valid" :
      case "Busy" :
          tmp = "";
          break;
      
    }
  } else {
    tmp = "";
  }
  var tmp$1;
  if (status !== undefined) {
    switch (status) {
      case "Valid" :
          tmp$1 = JsxRuntime.jsx("label", {
                children: JsxRuntime.jsx(IconOk.make, {
                      size: "MD",
                      color: "Teal"
                    }),
                className: css.icon
              });
          break;
      case "Error" :
          tmp$1 = null;
          break;
      case "Busy" :
          tmp$1 = JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Spinner.make, {
                      size: "MD",
                      color: "Teal",
                      centered: true
                    }),
                className: css.spinner
              });
          break;
      
    }
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ReactGeosuggest, {
                      id: props.id,
                      radius: props.radius,
                      initialValue: props.value,
                      placeholder: placeholder,
                      disabled: disabled,
                      inputClassName: Cx.cx([
                            css.input,
                            disabled ? css.disabled : "",
                            tmp,
                            className
                          ]),
                      location: Caml_option.some(props.location),
                      onChange: props.onChange,
                      onSuggestSelect: (function (suggest) {
                          if (suggest !== undefined) {
                            return onSuggestSelect(suggest);
                          }
                          
                        }),
                      onBlur: props.onBlur
                    }),
                tmp$1
              ],
              className: css.wrapper
            });
}

var make = ReactGeoSuggestField;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as CreateInternetRfpProjectConfiguration from "./CreateInternetRfpProjectConfiguration.res.js";
import * as CreateInternetRfpProjectConfigurationData from "./CreateInternetRfpProjectConfigurationData.res.js";
import * as CreateInternetRfpProjectConfigurationForm from "./CreateInternetRfpProjectConfigurationForm.res.js";

function CreateInternetRfpProjectConfiguration__JsBridge$default(props) {
  var geographicalRegion = props.geographicalRegion;
  var documents = props.documents;
  var status = props.status;
  var railsContext = props.railsContext;
  var options = props.options;
  var configurations = props.configurations;
  var projectId = props.projectId;
  var options$1 = React.useMemo((function () {
          return CreateInternetRfpProjectConfigurationData.Options.fromJson(options);
        }), [options]);
  var context = React.useMemo((function () {
          var projectId$1 = Belt_Result.getExn(Json_Decode$JsonCombinators.decode(projectId, Json_Decode$JsonCombinators.option(Project.Id.decoder)));
          var configurations$1 = Belt_Result.getExn(Json_Decode$JsonCombinators.decode(configurations, Json_Decode$JsonCombinators.array(CreateInternetRfpProjectConfigurationForm.Configuration.inputDecoder(options$1.contractLengths))));
          var geographicalRegion$1 = Belt_Option.getWithDefault((geographicalRegion == null) ? undefined : Caml_option.some(geographicalRegion), "");
          if (projectId$1 !== undefined) {
            return {
                    TAG: "Project",
                    geographicalRegion: geographicalRegion$1,
                    projectId: Caml_option.valFromOption(projectId$1),
                    data: configurations$1
                  };
          } else if (configurations$1.length !== 0) {
            return {
                    TAG: "Draft",
                    geographicalRegion: geographicalRegion$1,
                    data: configurations$1
                  };
          } else {
            return {
                    TAG: "Draft",
                    geographicalRegion: geographicalRegion$1,
                    data: undefined
                  };
          }
        }), [
        projectId,
        configurations,
        geographicalRegion
      ]);
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectStatus = React.useMemo((function () {
          return Project.Status.fromString(status);
        }), [status]);
  var alreadyUploadedDocuments = React.useMemo((function () {
          return Belt_Array.map(documents, Project.$$Document.fromJs);
        }), [documents]);
  return JsxRuntime.jsx(CreateInternetRfpProjectConfiguration.make, {
              context: context,
              options: options$1,
              mobile: railsContext$1.mobile,
              userLoginStatus: railsContext$1.userLoginStatus,
              setUserData: props.reduxfunction.setUserData,
              projectStatus: projectStatus,
              alreadyUploadedDocuments: alreadyUploadedDocuments
            });
}

var Options;

var $$default = CreateInternetRfpProjectConfiguration__JsBridge$default;

export {
  Options ,
  $$default as default,
}
/* react Not a pure module */
